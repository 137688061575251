/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onRouteUpdate = () => {
  if (
    process.env.NODE_ENV === `production` &&
    typeof window.plausible !== `undefined`
  ) {
    window.plausible(`pageview`);
  }
};
